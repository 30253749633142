import * as React from "react";
import {Component, FormEvent, ReactNode} from "react";
import {Notice, NoticeLevel} from "../model";
import {BackendService, NavigationService} from "../services";

const preventDefault = (action: (event: FormEvent<HTMLFormElement>) => void): (event: FormEvent<HTMLFormElement>) => void =>
    event => {
        event.preventDefault();
        action(event);
    };

type PropsType = {
    original?: Notice;
    onChange: () => void;
}

type StateType = {
    message: string;
    level: NoticeLevel;
    errorMessage: string | null;
}

class AddNotice extends Component<PropsType, StateType> {

    state = {
        message: this.props.original ? this.props.original!.message : "",
        level: this.props.original ? this.props.original!.level : NoticeLevel.INFO,
        errorMessage: null,
    };

    render(): ReactNode {
        const {original} = this.props;
        const {message, level, errorMessage} = this.state;
        return (
            <div className="text-left ml-3 ml-md-5 mr-3 mr-md-5 pl-lg-5 pr-lg-5">
                <h3 className="pb-2">{original ? "Ändra notis" : "Lägg till notis"}</h3>
                {errorMessage &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                <form onSubmit={preventDefault(() => original ? this.editNotice() : this.addNotice())}>
                    <div className="form-group row">
                        <label htmlFor="message" className="col-sm-2 col-form-label">Meddelande</label>
                        <div className="col-sm-10">
                            <input id="message"
                                   className="form-control"
                                   type="text"
                                   name="message"
                                   minLength={1}
                                   maxLength={150}
                                   value={message}
                                   onChange={event => this.setState({message: event.target.value})}
                            />
                        </div>
                    </div>
                    <fieldset className="form-group row">
                        <legend className="sr-only">Typ</legend>
                        <div className="col-form-label col-sm-2 float-sm-left pt-0" aria-hidden="true">Typ</div>
                        <div className="col-sm-10">
                            <div className="form-check">
                                <input id="levelTip"
                                       className="form-check-input"
                                       type="radio"
                                       name="level"
                                       value={NoticeLevel.TIP}
                                       checked={level === NoticeLevel.TIP}
                                       onChange={event => this.setState({level: parseInt(event.target.value)})}
                                />
                                <label className="form-check-label" htmlFor="levelTip">
                                    Tips
                                </label>
                            </div>
                            <div className="form-check">
                                <input id="levelInfo"
                                       className="form-check-input"
                                       type="radio"
                                       name="level"
                                       value={NoticeLevel.INFO}
                                       checked={level === NoticeLevel.INFO}
                                       onChange={event => this.setState({level: parseInt(event.target.value)})}
                                />
                                <label className="form-check-label" htmlFor="levelInfo">
                                    Info
                                </label>
                            </div>
                            <div className="form-check disabled">
                                <input id="levelImportant"
                                       className="form-check-input"
                                       type="radio"
                                       name="level"
                                       value={NoticeLevel.IMPORTANT}
                                       checked={level === NoticeLevel.IMPORTANT}
                                       onChange={event => this.setState({level: parseInt(event.target.value)})}
                                />
                                <label className="form-check-label" htmlFor="levelImportant">
                                    Viktigt
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <button className="btn btn-primary" type="submit">
                                {original ? "Spara" : "Lägg till"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async addNotice(): Promise<void> {
        const {message, level} = this.state;
        const newNotice = new Notice(-1, message.trim(), level);
        try {
            await BackendService.addNotice(newNotice);
        } catch (ignored) {
            this.setState({errorMessage: "Kunde inte lägga till notis. Vänligen pröva igen."});
            return;
        }
        this.props.onChange();
        NavigationService.goTo("");
    }

    async editNotice(): Promise<void> {
        const {original} = this.props;
        const {message, level} = this.state;
        const trimmedMessage = message.trim();
        if (original!.message !== trimmedMessage || original!.level !== level) {
            const editedNotice = new Notice(original!.id, trimmedMessage, level);
            try {
                await BackendService.editNotice(editedNotice);
            } catch (ignored) {
                this.setState({errorMessage: "Kunde inte ändra notis. Vänligen pröva igen."});
                return;
            }
            this.props.onChange();
        }
        NavigationService.goTo("");
    }

}

export default AddNotice;
