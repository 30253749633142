import * as React from "react";
import {Component, ReactNode} from "react";

class Start extends Component {

    render(): ReactNode {
        return (
            <div>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel
                    condimentum tellus. Sed et sagittis metus, et luctus magna. Nulla
                    vitae gravida nunc. Mauris viverra, justo scelerisque dignissim
                    aliquet, enim nisi laoreet dolor, non rhoncus massa dolor sed erat.
                    Vivamus in facilisis enim. Quisque consequat justo sit amet odio
                    faucibus tincidunt. Suspendisse non congue felis. Mauris interdum nibh
                    eu est fringilla placerat. Pellentesque feugiat viverra ipsum, sit
                    amet ullamcorper diam. Suspendisse vulputate lobortis arcu.
                </p>
                <p>
                    Nullam fermentum tincidunt nibh eu cursus. Sed laoreet orci id sem
                    sollicitudin, quis volutpat purus malesuada. Phasellus sed dolor
                    pharetra, accumsan nunc et, vehicula mauris. Aliquam euismod efficitur
                    vehicula. Quisque elementum augue quis dictum ultricies. Donec blandit
                    fermentum viverra. Morbi dolor ante, malesuada quis ipsum et,
                    tincidunt facilisis est.
                </p>
                <p>
                    Nulla a nibh placerat, interdum nisi a, hendrerit urna. Morbi
                    vestibulum fringilla leo et aliquam. Fusce ultrices massa sed quam
                    porttitor, vitae blandit arcu aliquam. Curabitur commodo magna vitae
                    quam rhoncus, vitae fermentum justo posuere. Duis vehicula, justo ut
                    dignissim fermentum, metus nibh consectetur neque, vitae convallis
                    magna eros eget leo. Fusce malesuada, enim tempus iaculis tempor, nisi
                    enim imperdiet massa, eget imperdiet nisl nisl lobortis justo. Fusce
                    enim diam, pulvinar quis auctor vel, facilisis quis arcu. Ut aliquet
                    metus sed felis volutpat, quis varius nisl iaculis. Vestibulum
                    scelerisque, ante a egestas scelerisque, justo libero feugiat eros, ut
                    bibendum nibh ipsum ac metus. Vestibulum augue lorem, maximus ac
                    mauris eget, mattis laoreet dolor. Sed suscipit sapien id tellus
                    consectetur, ut maximus nibh vestibulum.
                </p>
                <p>
                    Donec ut tellus vel leo vestibulum aliquet. Vestibulum rhoncus orci
                    felis, sed maximus turpis posuere non. Mauris a nisi sed justo
                    accumsan luctus at at purus. Vivamus non risus at nunc iaculis egestas
                    eget quis est. Sed viverra risus nisl, et rhoncus erat sodales in.
                    Morbi vitae dapibus mauris. Maecenas sollicitudin ornare metus, quis
                    porta neque dapibus luctus. Cras sit amet justo in augue facilisis
                    ultrices. Etiam a aliquet est. In nec condimentum justo.
                </p>
                <p>
                    Vivamus non libero at erat euismod pharetra et a justo. Sed vitae
                    maximus odio. Curabitur finibus tortor ex, vel tempor lectus porta
                    vitae. Sed fringilla libero non lectus fermentum, iaculis tristique
                    urna lobortis. Sed ut finibus ante, ac egestas neque. Nunc ornare urna
                    ut sem iaculis, quis faucibus velit commodo. Pellentesque fringilla,
                    urna ac vulputate iaculis, tellus purus malesuada quam, id finibus
                    nisi ligula sit amet urna. Duis sollicitudin tortor vel ornare
                    ultricies. Phasellus euismod convallis ipsum, in ullamcorper metus
                    vestibulum in. Vestibulum commodo in elit ac scelerisque. Praesent
                    feugiat sit amet mi nec faucibus. Aenean vitae posuere mauris.
                    Praesent egestas, mi nec elementum iaculis, magna nisi mollis quam,
                    sed posuere ligula dolor pretium odio. Quisque id elit sed mi ultrices
                    varius. Cras sit amet pellentesque velit.
                </p>
            </div>
        );
    }

}

export default Start;
