import {Notice, ScheduleItem, User} from "../model";
import {ArrayUtil} from "../util";

const backendRoot = process.env.NODE_ENV === "production" ? "dynamic/" : "http://localhost:8000/";

type HttpResponse = {
    httpStatus: number;
    responseBody: any;
}

type CheckIdResult = {
    user: User | null;
    loggedIn: boolean;
}

type EnterCodeResult = {
    correct: boolean;
    numCorrect?: number;
    numLeft?: number;
}

class BackendService {

    public async checkId(id: string | null, hash: string | null): Promise<CheckIdResult> {
        const requestBody = {
            id,
            hash,
        };
        const {responseBody} = await performAsync("checkId", requestBody);
        const user = User.fromRaw(responseBody.user);
        if (user === null) {
            return {user, loggedIn: false};
        } else if (user instanceof User) {
            return {user, loggedIn: responseBody.loggedIn};
        }
        throw new TypeError("Return type is not a user.");
    }

    public async logIn(memberId: string, password: string): Promise<User> {
        const requestBody = {
            memberId,
            password,
        };
        const {responseBody} = await performAsync("logIn", requestBody);
        const user = User.fromRaw(responseBody.user);
        if (user instanceof User) {
            return user;
        }
        throw new TypeError("Return type is not a user.");
    }

    public async logOut(): Promise<void> {
        await performAsync("logOut");
    }

    public async addNotice(notice: Notice): Promise<Notice> {
        const requestBody = {
            notice,
        };
        const {responseBody} = await performAsync("addNotice", requestBody);
        const addedNotice = Notice.fromRaw(responseBody.notice);
        if (addedNotice instanceof Notice) {
            return addedNotice;
        }
        throw new TypeError("Return type is not a notice.");
    }

    public async editNotice(notice: Notice): Promise<Notice> {
        const requestBody = {
            notice,
        };
        const {responseBody} = await performAsync("editNotice", requestBody);
        const editedNotice = Notice.fromRaw(responseBody.notice);
        if (editedNotice instanceof Notice) {
            return editedNotice;
        }
        throw new TypeError("Return type is not a notice.");
    }

    public async deleteNotice(noticeId: number): Promise<void> {
        const requestBody = {
            noticeId,
        };
        await performAsync("deleteNotice", requestBody);
    }

    public async getNotices(): Promise<Notice[]> {
        const {responseBody} = await performAsync("getNotices");
        const notices = Notice.fromRaw(responseBody.notices);
        if (ArrayUtil.isArrayOfType<Notice>(notices, Notice)) {
            return notices;
        }
        throw new TypeError("Return type is not an array of notices.");
    }

    public async addScheduleItem(scheduleItem: ScheduleItem): Promise<ScheduleItem> {
        const requestBody = {
            scheduleItem,
        };
        const {responseBody} = await performAsync("addScheduleItem", requestBody);
        const addedScheduleItem = ScheduleItem.fromRaw(responseBody.scheduleItem);
        if (addedScheduleItem instanceof ScheduleItem) {
            return addedScheduleItem;
        }
        throw new TypeError("Return type is not a schedule item.");
    }

    public async editScheduleItem(scheduleItem: ScheduleItem): Promise<ScheduleItem> {
        const requestBody = {
            scheduleItem,
        };
        const {responseBody} = await performAsync("editScheduleItem", requestBody);
        const editedScheduleItem = ScheduleItem.fromRaw(responseBody.scheduleItem);
        if (editedScheduleItem instanceof ScheduleItem) {
            return editedScheduleItem;
        }
        throw new TypeError("Return type is not a schedule item.");
    }

    public async deleteScheduleItem(scheduleItemId: number): Promise<void> {
        const requestBody = {
            scheduleItemId,
        };
        await performAsync("deleteScheduleItem", requestBody);
    }

    public async getScheduleItems(): Promise<ScheduleItem[]> {
        const {responseBody} = await performAsync("getScheduleItems");
        const scheduleItems = ScheduleItem.fromRaw(responseBody.scheduleItems);
        if (ArrayUtil.isArrayOfType<ScheduleItem>(scheduleItems, ScheduleItem)) {
            return scheduleItems;
        }
        throw new TypeError("Return type is not an array of schedule items.");
    }

    public async enterCode(code: string): Promise<EnterCodeResult> {
        const requestBody = {
            code,
        };
        const {responseBody: {correct, numCorrect, numLeft}} = await performAsync("enterCode", requestBody);
        if (correct === true && Number.isInteger(numCorrect) && Number.isInteger(numLeft)) {
            return {
                correct,
                numCorrect,
                numLeft,
            };
        } else if (correct === false) {
            return {
                correct,
            };
        } else {
            throw new TypeError("Return data is invalid.");
        }
    }

}

async function performAsync(action: string, requestBody?: any): Promise<HttpResponse> {
    const url = backendRoot + "api.php?action=" + action;
    return await postAsync(url, requestBody);
}

function postAsync(url: string | URL, requestBody?: any): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                const httpStatus = xhr.status;
                const responseBody = xhr.responseText ? JSON.parse(xhr.responseText) : {};
                if (httpStatus >= 200 && httpStatus < 300) {
                    resolve({httpStatus, responseBody});
                } else {
                    reject({httpStatus, responseBody});
                }
            }
        };
        xhr.setRequestHeader("Content-Type", "text/plain; charset=UTF-8");
        xhr.withCredentials = true;
        xhr.send(requestBody ? JSON.stringify(requestBody) : null);
    });
}

export type {CheckIdResult, EnterCodeResult};
export default new BackendService();
