import * as React from "react";
import {Component, FormEvent, ReactNode} from "react";
import {GlobalContextConsumer} from "../context";
import {Images} from "../media";
import {AccessLevel} from "../model";
import {BackendService, UserData, UserService} from "../services";
import Status from "./Status";

const CODE_MAX_LENGTH = 50;
const PASSWORD_MAX_LENGTH = 100;

const preventDefault = (action: (event: FormEvent<HTMLFormElement>) => void): (event: FormEvent<HTMLFormElement>) => void =>
    event => {
        event.preventDefault();
        action(event);
    };

type StateType = {
    puzzleStatus: ReactNode;
    loginStatus: ReactNode;
    code: string;
    password: string;
}

class UserArea extends Component<{}, StateType> {

    state: StateType = {
        puzzleStatus: null,
        loginStatus: null,
        code: "",
        password: "",
    };

    async enterCode(): Promise<void> {
        const {code} = this.state;
        if (code.length > 0 && code.length <= CODE_MAX_LENGTH) {
            try {
                const result = await BackendService.enterCode(code);
                if (result.correct) {
                    const {numCorrect, numLeft} = result;
                    const total = (numCorrect as number) + (numLeft as number);
                    this.setState({
                        puzzleStatus: <Status type="success"
                                              message={`Bra jobbat! Du har klarat ${numCorrect} av ${total}. ${JSON.stringify(result)}`}/>,
                        code: "",
                    });
                    return;
                }
                this.setState({
                    puzzleStatus: <Status type="error"
                                          message={`${JSON.stringify(result)}`}/>,
                    code: "",
                });
            } catch (error) {
                let e2 = error;
                try{
                    e2 = JSON.stringify(error);
                }catch (ignored){}
                this.setState({
                    puzzleStatus: <Status type="error"
                                          message={`${e2}`}/>,
                    code: "",
                });
                if (!(error instanceof TypeError)) {
                    throw error;
                }
            }
        }
        this.setState({
            puzzleStatus: null,
            code: "",
        });
    }

    async logIn(): Promise<void> {
        const {user} = await UserService.getUserData();
        const {password} = this.state;
        const memberId = user?.memberId;
        if (memberId && password.length > 0 && password.length <= PASSWORD_MAX_LENGTH) {
            try {
                const result = await UserService.logIn(memberId, password);
                if (result) {
                    this.setState({
                        loginStatus: null,
                        password: "",
                    });
                    return;
                }
            } catch (error: any) {
                if (!(error instanceof TypeError || error.httpStatus === 400)) {
                    throw error;
                }
            }
        }
        this.setState({
            loginStatus: <Status type="error" message={"Kunde inte logga in!"}/>,
            password: "",
        });
    }

    async logOut(): Promise<void> {
        await UserService.logOut();
    }

    render(): ReactNode {
        return (
            <GlobalContextConsumer>
                {({userData}) => this.renderWithUserData(userData)}
            </GlobalContextConsumer>
        );
    }

    renderWithUserData({user, loggedIn}: UserData): ReactNode {
        if (!user) {
            return null;
        }
        const shouldShowLogInForm = !loggedIn && user.verified && user.accessLevel > AccessLevel.REGULAR;
        return (
            <>
                {this.renderPuzzleForm()}
                {shouldShowLogInForm && this.renderLogInForm()}
                {loggedIn && this.renderLogOutForm()}
            </>
        );
    }

    renderPuzzleForm(): ReactNode {
        const {puzzleStatus, code} = this.state;
        return (
            <div
                className="card"
                style={{background: "#DDDDDD"}}
            >
                <div className="card-body">
                    <form onSubmit={preventDefault(() => this.enterCode())} method="post">
                        <div className="input-group justify-content-center">
                            <input
                                className="form-control col-12 col-md-10 col-lg-8"
                                name="code"
                                type="text"
                                value={code}
                                placeholder="Ange svaret här"
                                maxLength={CODE_MAX_LENGTH}
                                onChange={event => this.setState({code: event.target.value})}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary btn-image"
                                    type="submit"
                                >
                                    <img
                                        className="half-opacity"
                                        src={Images.puzzlePiece}
                                        alt="Pussel"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                    {puzzleStatus && <div className="mt-1">{puzzleStatus}</div>}
                </div>
            </div>
        );
    }

    renderLogInForm(): ReactNode {
        const {loginStatus, password} = this.state;
        return (
            <div
                className="card mt-3"
                style={{background: "#DDDDDD"}}
            >
                <div className="card-body">
                    <form onSubmit={preventDefault(() => this.logIn())} method="post">
                        <div className="input-group justify-content-center">
                            <input
                                className="form-control col-12 col-md-6 col-lg-4"
                                name="password"
                                type="password"
                                value={password}
                                placeholder="Lösenord"
                                maxLength={PASSWORD_MAX_LENGTH}
                                onChange={event => this.setState({password: event.target.value})}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    type="submit"
                                >
                                    Logga in
                                </button>
                            </div>
                        </div>
                    </form>
                    {loginStatus && <div className="mt-1">{loginStatus}</div>}
                </div>
            </div>
        );
    }

    renderLogOutForm(): ReactNode {
        return (
            <div
                className="card mt-3"
                style={{background: "#DDDDDD"}}
            >
                <div className="card-body">
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => this.logOut()}
                    >
                        Logga ut
                    </button>
                </div>
            </div>
        );
    }

}

export default UserArea;
