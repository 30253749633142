const contact = require("./contact.png") as string;
const logo = require("./logo.png") as string;
const map = require("./map.png") as string;
const puzzlePiece = require("./puzzle_piece.png") as string;
const rooms = require("./rooms.png") as string;
const schedule = require("./schedule.png") as string;
const sundsvall = require("./sundsvall.png") as string;

const Images = {
    contact,
    logo,
    map,
    puzzlePiece,
    rooms,
    schedule,
    sundsvall,
};

export {Images};
