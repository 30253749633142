import * as React from "react";
import {Component, createContext, PropsWithChildren, ReactNode} from "react";
import {UserData, UserService} from "../services";

type StateType = {
    userData: UserData;
}

type GlobalContextValueType = StateType

const initialState: StateType = {
    userData: {
        user: null,
        loggedIn: false,
    },
};

const initialContextValue: GlobalContextValueType = {
    ...initialState,
};

const GlobalContext = createContext(initialContextValue);

class GlobalContextProvider extends Component<PropsWithChildren<{}>, StateType> {

    state = {...initialState};

    async componentDidMount(): Promise<void> {
        UserService.registerUserDataChangeListener("GlobalContext", this.onUserDataChanged.bind(this), true);
    }

    componentWillUnmount() {
        UserService.unregisterUserDataChangeListener("GlobalContext");
    }

    onUserDataChanged(userData: UserData) {
        this.setState({userData});
    }

    render(): ReactNode {
        const {children} = this.props;

        return (
            <GlobalContext.Provider value={{...this.state}}>
                {children}
            </GlobalContext.Provider>
        );
    }

}

const GlobalContextConsumer = GlobalContext.Consumer;

export type {GlobalContextValueType};
export {GlobalContextProvider, GlobalContextConsumer};
export default GlobalContext;
