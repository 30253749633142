import * as React from "react";
import {Component, ReactNode} from "react";

type PropsType = {
    type: "success" | "error";
    message: string;
}

class Status extends Component<PropsType, {}> {

    render(): ReactNode {
        const {type, message} = this.props;
        return <div className={type}>{message}</div>;
    }

}

export default Status;
