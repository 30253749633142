import * as dayjs from "dayjs";
import * as React from "react";
import {Component, ReactNode, SyntheticEvent} from "react";
import {GlobalContextConsumer} from "../context";
import {AccessLevel, ScheduleItem} from "../model";
import {BackendService, NavigationService} from "../services";
import {ArrayUtil, DateUtil, DialogUtil, StringUtil} from "../util";
import "./Schedule.css";
import SubPage from "./SubPage";

const stopPropagation = function <T extends SyntheticEvent>(action: (event: T) => void): (event: T) => void {
    return event => {
        event.stopPropagation();
        action(event);
    };
};

type StateType = {
    scheduleItems: ScheduleItem[];
    filteredDate: string | null;
    filteredRoom: string | null;
}

const defaultRooms = [
    "Apotekaren",
    "Bagaren",
    "Bryggaren",
    "Flottaren",
    "Flygaren",
    "Folkan",
    "Glasblåsaren",
    "Hattmakaren",
    "Hovslagaren",
    "Klockaren",
    "Mjölnaren",
    "Muraren",
    "Rallaren",
    "Tunnbindaren",
];

class Schedule extends Component<{}, StateType> {

    state = {
        scheduleItems: new Array<ScheduleItem>(),
        filteredDate: null,
        filteredRoom: null,
    };

    private getAvailableDates(): string[] {
        const {scheduleItems} = this.state;
        return scheduleItems.reduce((result, current) => {
            if (result.indexOf(current.date) < 0) {
                result.push(current.date);
            }
            return result;
        }, new Array<string>());
    }

    private getAvailableRooms(): string[] {
        const {scheduleItems} = this.state;
        return scheduleItems.reduce((result, current) => {
            const roomName = defaultRooms.find(room => StringUtil.caseInsensitiveEquals(room, current.room)) || "";
            if (!result.some(room => StringUtil.caseInsensitiveEquals(room, roomName))) {
                result.push(roomName);
            }
            return result;
        }, new Array<string>());
    }

    async componentDidMount(): Promise<void> {
        const currentDate = new Date().toISOString().substring(0, 10);
        await this.setStateAsync({filteredDate: currentDate});
        await this.reloadScheduleItems();
    }

    async reloadScheduleItems(): Promise<void> {
        const scheduleItems = await BackendService.getScheduleItems();
        await this.setScheduleItems(scheduleItems);
    }

    async setScheduleItems(scheduleItems: ScheduleItem[]): Promise<void> {
        const {filteredDate, filteredRoom} = this.state;
        this.setState({
            scheduleItems,
            filteredDate: ArrayUtil.contains(scheduleItems, item => item.date === filteredDate) ? filteredDate : null,
            filteredRoom: ArrayUtil.contains(scheduleItems, item => StringUtil.caseInsensitiveEquals(item.room, filteredRoom)) ? filteredRoom : null,
        });
    }

    render(): ReactNode {
        const {scheduleItems: allScheduleItems, filteredDate, filteredRoom} = this.state;
        const dateFilter = filteredDate ? (item: ScheduleItem) => item.date === filteredDate : () => true;
        const roomFilter = filteredRoom === null ?
            () => true :
            filteredRoom === "" ?
                (item: ScheduleItem) => !defaultRooms.find(room => StringUtil.caseInsensitiveEquals(room, item.room)) :
                (item: ScheduleItem) => StringUtil.caseInsensitiveEquals(item.room, filteredRoom);
        const filteredScheduleItems = allScheduleItems.filter(dateFilter).filter(roomFilter);
        filteredScheduleItems.sort((lhs, rhs) =>
            lhs.date.localeCompare(rhs.date) ||
            Schedule.compareTime(lhs.startTime, rhs.startTime) ||
            -lhs.bgcolor.localeCompare(rhs.bgcolor) ||
            Schedule.compareTime(lhs.endTime, rhs.endTime) ||
            lhs.title.localeCompare(rhs.title) ||
            rhs.id - lhs.id,
        );
        let prevDate: string | null = null;
        let prevTime: string | null = null;
        return (
            <div className="Schedule container">
                <div className="row justify-content-center">
                    <div className="col-12 mb-2">
                        <div className="btn-toolbar justify-content-center" role="toolbar"
                             aria-label="Toolbar with button groups">
                            <div className="col-12 m-1" role="group" aria-label="Filter för dag">
                                {this.getAvailableDates().sort().map(date => (
                                    <button key={date} type="button"
                                            className={"btn m-1 btn" + (date === filteredDate ? "" : "-outline") + "-secondary"}
                                            onClick={() => this.setState({filteredDate: date === filteredDate ? null : date})}>
                                        {DateUtil.getWeekdayFromDate(date)}
                                    </button>
                                ))}
                            </div>
                            <div className="col-12 m-1" role="group" aria-label="Filter för rum/plats">
                                {this.getAvailableRooms()
                                    .sort((lhs, rhs) => {
                                        const compare = StringUtil.caseInsensitiveCompare(lhs, rhs);
                                        return lhs && rhs ? compare : -compare;
                                    })
                                    .map(room => (
                                        <button key={room} type="button"
                                                className={"btn m-1 btn" + (room === filteredRoom ? "" : "-outline") + "-secondary"}
                                                onClick={() => this.setState({filteredRoom: room === filteredRoom ? null : room})}>
                                            {room || "Övriga"}
                                        </button>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <GlobalContextConsumer>
                    {({
                          userData: {
                              user,
                              loggedIn,
                          },
                      }) => loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR &&
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-link"
                                        onClick={() => NavigationService.goTo(SubPage.ADD_SCHEDULE_ITEM)}>
                                    Lägg till händelse
                                </button>
                            </div>
                        </div>
                    }
                </GlobalContextConsumer>
                {filteredScheduleItems.map(item => {
                    let dayHeader: string | null = null;
                    if (prevDate !== item.date) {
                        dayHeader = DateUtil.getWeekdayFromDate(item.date);
                        prevDate = item.date;
                        prevTime = null;
                    }
                    let timeHeader: string | null = null;
                    if (prevTime !== item.startTime) {
                        timeHeader = Schedule.formatTime(item.startTime);
                        prevTime = item.startTime;
                    }
                    return <React.Fragment key={item.id}>
                        {dayHeader && <div className="Schedule__day row justify-content-center">
                            <div className="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-5 mt-3 text-left">
                                <h2>{dayHeader}</h2>
                            </div>
                        </div>}
                        {timeHeader && <div className="Schedule__time row justify-content-center">
                            <div className="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-5 mt-2 text-left">
                                <h3>{timeHeader}</h3>
                            </div>
                        </div>}
                        <div className="Schedule__item row justify-content-center">
                            <div className="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-5 mt-2 mb-2">
                                {this.renderScheduleItem(item)}
                            </div>
                        </div>
                    </React.Fragment>;
                })}
            </div>
        );
    }

    private renderScheduleItem(item: ScheduleItem): ReactNode {
        const duration = Schedule.calculateDuration(item.startTime, item.endTime);
        return (
            <div className="card text-left"
                 style={{backgroundColor: (item.bgcolor || "auto")}}
                 role={item.swagLink ? "button" : "none"}
                 onClick={item.swagLink ? () => window.location.assign(item.swagLink) : () => undefined}
            >
                <div className="card-body pt-3 pb-3">
                    {duration !== 1 &&
                        <div
                            className="duration card-text">{duration.toLocaleString("sv-SE", {maximumFractionDigits: 2})}h</div>}
                    <h4 className="card-title">{item.title}</h4>
                    <div className="preamble card-text">{item.preamble ||
                        <span dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>}</div>
                    <div className="room card-text">{item.room}</div>

                    <GlobalContextConsumer>
                        {({userData: {user, loggedIn}}) =>
                            loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR &&
                            <>
                                <hr/>
                                <p className="mb-0 small">
                                    <button className="btn btn-link alert-link pt-0 pb-0"
                                            onClick={stopPropagation(() => this.editScheduleItem(item))}>
                                        Ändra
                                    </button>
                                    <button className="btn btn-link alert-link pt-0 pb-0"
                                            onClick={stopPropagation(() => this.deleteScheduleItem(item))}>
                                        Ta bort
                                    </button>
                                </p>
                            </>}
                    </GlobalContextConsumer>
                </div>
            </div>
        );
    }

    // noinspection JSMethodCanBeStatic
    private async editScheduleItem(scheduleItem: ScheduleItem): Promise<void> {
        NavigationService.goTo(SubPage.EDIT_SCHEDULE_ITEM, scheduleItem);
    }

    private async deleteScheduleItem(scheduleItem: ScheduleItem): Promise<void> {
        if (await DialogUtil.confirm("Är du säker på att du vill ta bort händelsen?")) {
            try {
                await BackendService.deleteScheduleItem(scheduleItem.id);
            } catch (ignored) {
                return await this.reloadScheduleItems();
            }
            const {scheduleItems} = this.state;
            await this.setScheduleItems(scheduleItems.filter(si => si.id !== scheduleItem.id));
        }
    }

    private async setStateAsync<K extends keyof StateType>(state: StateType | Pick<StateType, K> | null): Promise<void> {
        return new Promise(resolve => this.setState(state, resolve));
    }

    private static compareTime(lhs: string, rhs: string): number {
        let lhsDay = dayjs(lhs, "HH:mm:ss");
        if (lhsDay.hour() < 5 && !lhs.startsWith("24")) {
            lhsDay = lhsDay.add(1, "day");
        }
        let rhsDay = dayjs(rhs, "HH:mm:ss");
        if (rhsDay.hour() < 5 && !rhs.startsWith("24")) {
            rhsDay = rhsDay.add(1, "day");
        }
        return lhsDay.diff(rhsDay, "hour", true);
    }

    private static formatTime(time: string): string {
        return dayjs(time, "HH:mm:ss").format("HH:mm");
    }

    private static calculateDuration(startTime: string, endTime: string): number {
        const duration = dayjs(endTime, "HH:mm:ss").diff(dayjs(startTime, "HH:mm:ss"), "hour", true);
        return duration < 0 ? duration + 24 : duration;
    }

}

export default Schedule;
