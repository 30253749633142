type UserOrArray = User | Array<UserOrArray> | null;

const enum AccessLevel {
    REGULAR = 0,
    MODERATOR = 1,
    ADMIN = 2,
    SUPER_ADMIN = 3
}

class User {

    public id: number = -1;
    public memberId: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public verified: boolean = false;
    public accessLevel: AccessLevel = AccessLevel.REGULAR;

    public constructor(id: number, memberId: string, firstName: string, lastName: string, verified: boolean, accessLevel: AccessLevel) {
        this.id = id;
        this.memberId = memberId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.verified = verified;
        this.accessLevel = accessLevel;
    }

    public static fromRaw(raw: any): UserOrArray {
        if (raw == null) {
            return null;
        } else if (Array.isArray(raw)) {
            return raw.map(val => this.fromRaw(val));
        } else {
            return new User(raw.id, raw.memberId, raw.firstName, raw.lastName, raw.verified, raw.accessLevel);
        }
    }

    public equals(that: any) {
        return (
            this === that ||
            (that instanceof User &&
                this.id === that.id &&
                this.memberId === that.memberId &&
                this.firstName === that.firstName &&
                this.lastName === that.lastName &&
                this.verified === that.verified &&
                this.accessLevel === that.accessLevel
            )
        );
    }

    public static equals(lhs: any, rhs: any) {
        return lhs === rhs || (lhs instanceof User && lhs.equals(rhs));
    }

}

export {AccessLevel};
export default User;
