type NoticeOrArray = Notice | Array<NoticeOrArray> | null;

const enum NoticeLevel {
    TIP = 1,
    INFO = 2,
    IMPORTANT = 3,
}

class Notice {

    public id: number = -1;
    public message: string = "";
    public level: NoticeLevel = NoticeLevel.TIP;

    public constructor(id: number, message: string, level: NoticeLevel) {
        this.id = id;
        this.message = message;
        this.level = level;
    }

    public static fromRaw(raw: any): NoticeOrArray {
        if (raw == null) {
            return null;
        } else if (Array.isArray(raw)) {
            return raw.map(val => this.fromRaw(val));
        } else {
            return new Notice(
                raw.id,
                raw.message,
                raw.level,
            );
        }
    }

    public equals(that: any) {
        return (
            this === that ||
            (that instanceof Notice &&
                this.id === that.id &&
                this.message === that.message &&
                this.level === that.level
            )
        );
    }

    public static equals(lhs: any, rhs: any) {
        return lhs === rhs || (lhs instanceof Notice && lhs.equals(rhs));
    }

}

export {NoticeLevel};
export default Notice;
