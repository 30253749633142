class DateUtil {

    public getWeekdayFromDate(date: string | Date): string {
        if (date instanceof Date) {
            switch (date.getDay()) {
                case 1:
                    return "Måndag";
                case 2:
                    return "Tisdag";
                case 3:
                    return "Onsdag";
                case 4:
                    return "Torsdag";
                case 5:
                    return "Fredag";
                case 6:
                    return "Lördag";
                case 0:
                    return "Söndag";
                default:
                    throw new TypeError(`Unknown day ${date.getDay()}`);
            }
        }
        return this.getWeekdayFromDate(new Date(date));
    }

}

export default new DateUtil();
