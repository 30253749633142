type ScheduleItemOrArray = ScheduleItem | Array<ScheduleItemOrArray> | null;

class ScheduleItem {

    public id: number;
    public date: string;
    public startTime: string;
    public endTime: string;
    public title: string;
    public preamble: string;
    public room: string;
    public swagLink: string;
    public bgcolor: string;

    public constructor(
        id: number,
        date: string,
        startTime: string,
        endTime: string,
        title: string,
        preamble: string,
        room: string,
        swagLink: string,
        bgcolor: string,
    ) {
        this.id = id;
        this.date = date;
        this.startTime = startTime;
        this.endTime = endTime;
        this.title = title;
        this.preamble = preamble;
        this.room = room;
        this.swagLink = swagLink;
        this.bgcolor = bgcolor;
    }

    public static fromRaw(raw: any): ScheduleItemOrArray {
        if (raw == null) {
            return null;
        } else if (Array.isArray(raw)) {
            return raw.map(val => this.fromRaw(val));
        } else {
            return new ScheduleItem(
                raw.id,
                raw.date,
                raw.startTime,
                raw.endTime,
                raw.title,
                raw.preamble,
                raw.room,
                raw.swagLink,
                raw.bgcolor,
            );
        }
    }

    public equals(that: any) {
        return (
            this === that ||
            (that instanceof ScheduleItem &&
                this.id === that.id &&
                this.date === that.date &&
                this.startTime === that.startTime &&
                this.endTime === that.endTime &&
                this.title === that.title &&
                this.preamble === that.preamble &&
                this.room === that.room &&
                this.swagLink === that.swagLink &&
                this.bgcolor === that.bgcolor
            )
        );
    }

    public static equals(lhs: any, rhs: any) {
        return lhs === rhs || (lhs instanceof ScheduleItem && lhs.equals(rhs));
    }

}

export default ScheduleItem;
