class NumberUtil {

    public removeNonDigits(input: string): string {
        return input.replace(/\D/g, "");
    }

    public formatPhoneNumber(input: string): string {
        const rawNumbers = this.removeNonDigits(input);
        if (rawNumbers.length <= 5) {
            return rawNumbers;
        }
        let out = rawNumbers.substring(0, 3) + "-";
        let i: number;
        if (rawNumbers.length % 2 === 0) {
            out += rawNumbers.substring(3, 6);
            i = 6;
        } else {
            out += rawNumbers.substring(3, 5);
            i = 5;
        }
        for (; i + 2 <= rawNumbers.length; i += 2) {
            out += " " + rawNumbers.substring(i, i + 2);
        }
        return out;
    }

}

export default new NumberUtil();
