enum SubPage {
    SCHEDULE = "schedule",
    ROOMS = "rooms",
    CONTACT = "contact",
    ADD_NOTICE = "addNotice",
    EDIT_NOTICE = "editNotice",
    ADD_SCHEDULE_ITEM = "addScheduleItem",
    EDIT_SCHEDULE_ITEM = "editScheduleItem",
}

export default SubPage;
