import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {App} from "./components";
import {GlobalContextProvider} from "./context";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

dayjs.extend(customParseFormat);

ReactDOM.render(<GlobalContextProvider><App/></GlobalContextProvider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

function hasMouse(): boolean {
    return matchMedia("(pointer:fine)").matches;
}

function removeHoverStyling(): void {
    try {
        for (const styleSheetIndex in document.styleSheets) {
            const styleSheet = document.styleSheets[styleSheetIndex];
            if (styleSheet.cssRules) {
                for (let i = styleSheet.cssRules.length - 1; i >= 0; i--) {
                    if (styleSheet.cssRules[i].cssText && styleSheet.cssRules[i].cssText.match(":hover")) {
                        styleSheet.deleteRule(i);
                    }
                }
            }
        }
    } catch (e) {
    }
}

if (!hasMouse()) {
    removeHoverStyling();
}
