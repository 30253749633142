type LocationChangeListener = (newPage: string, oldPage: string, extra?: any) => void;

class NavigationService {

    private oldHash = "";
    private initiated = false;
    private previousPage: string | null = null;
    private locationChangeListeners = new Map<string, LocationChangeListener>();

    private init(): void {
        if (!this.initiated) {
            this.oldHash = window.location.hash;
            window.addEventListener("popstate", () => this.checkForLocationChanges());
            const observer = new MutationObserver(() => this.checkForLocationChanges());
            const body = document.querySelector("body")!;
            observer.observe(body, {childList: true, subtree: true});
            this.initiated = true;
        }
    }

    private checkForLocationChanges(extra?: any): void {
        const newHash = window.location.hash;
        if (this.oldHash !== newHash || extra) {
            const hash1 = NavigationService.sanitizeHash(newHash);
            const hash2 = NavigationService.sanitizeHash(this.oldHash);
            this.oldHash = newHash;
            this.locationChangeListeners.forEach(listener => listener(hash1, hash2, extra));
        }
    };

    private static sanitizeHash(hash: string): string {
        return hash[0] === "#" ? hash.substring(1) : hash;
    }

    public registerLocationChangeListener(id: string, listener: LocationChangeListener, triggerInitial?: boolean): void {
        this.init();
        this.locationChangeListeners.set(id, listener);
        if (triggerInitial) {
            listener(NavigationService.sanitizeHash(this.oldHash), "");
        }
    }

    public unregisterLocationChangeListener(id: string): void {
        this.locationChangeListeners.delete(id);
    }

    public goTo(page: string, extra?: any): void {
        window.location.assign(`#${page}`);
        window.scrollTo(0, 0);
        this.checkForLocationChanges(extra);
    }

    public setPreviousPage(page: string | null): void {
        this.previousPage = page;
    }

    public hasPreviousPage(): boolean {
        return this.previousPage !== null;
    }

    public goToPreviousPage(): void {
        if (this.previousPage !== null) {
            this.goTo(this.previousPage);
        }
    }

}

export type  {LocationChangeListener};
export default new NavigationService();
