import * as React from "react";
import {Component, ReactNode} from "react";
import {GlobalContextConsumer} from "../context";
import {AccessLevel, Notice, NoticeLevel} from "../model";

type PropsType = {
    notice: Notice;
    onEdit: () => void;
    onDelete: () => void;
}

class NoticeView extends Component<PropsType, {}> {

    render(): ReactNode {
        const {notice} = this.props;
        const noticeLevelString = this.getNoticeLevelString(notice);
        return (
            <div
                className={"alert mb-0 " + (
                    notice.level === NoticeLevel.IMPORTANT ? "alert-warning" :
                        notice.level === NoticeLevel.INFO ? "alert-info" :
                            "alert-secondary"
                )}>
                {noticeLevelString}: {notice.message}

                <GlobalContextConsumer>
                    {({userData: {user, loggedIn}}) =>
                        loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR &&
                        <>
                            <hr/>
                            <p className="mb-0 small">
                                <button className="btn btn-link alert-link pt-0 pb-0"
                                        onClick={this.props.onEdit}>
                                    Ändra
                                </button>
                                <button className="btn btn-link alert-link pt-0 pb-0"
                                        onClick={this.props.onDelete}>
                                    Ta bort
                                </button>
                            </p>
                        </>}
                </GlobalContextConsumer>
            </div>
        );
    }

    getNoticeLevelString(notice: Notice): string {
        switch (notice.level) {
            case NoticeLevel.TIP:
                return "Tips";
            case NoticeLevel.INFO:
                return "Info";
            case NoticeLevel.IMPORTANT:
                return "Viktigt";
        }
        throw new TypeError(`Unknown notice level '${notice.level}'`);
    }

}

export default NoticeView;
