import * as React from "react";
import {Component, ReactNode} from "react";
import {NumberUtil} from "../util";

type PersonInfo = {
    title: string;
    body?: ReactNode;
    mail?: string;
    phone?: string;
}

class Contact extends Component {

    render(): ReactNode {
        const personInfos = [
            {
                title: "Mensas reception",
                body:<>
                    Troliga öppettider:<br/>
                    Onsdag kl 15:00–20:00<br/>
                    Torsdag kl 10:00–19:00<br/>
                    Fredag kl 08:30–11:30<br/>
                    Lördag kl 08:30–10:30<br/>
                    Söndag kl 10:00–12:00
                </>,
                phone: "0730221390",
            },
            {
                title: "Allmänna frågor",
                mail: "2022@swag.mensa.se",
            },
            {
                title: "Årsträffsgeneral",
                body: "Per Thorneus",
                mail: "general@swag.mensa.se",
            },
            {
                title: "Trygghet",
                mail: "trygghet@swag.mensa.se",
            },
            {
                title: "Program/bemanning",
                body: "Lina Sponton & Birgitta Petersson",
                mail: "program@swag.mensa.se",
            },
            {
                title: "Festmiddag",
                body: "Teresia Bylger",
                mail: "festmiddag@swag.mensa.se",
            },
            {
                title: "Ekonomi",
                body: "Torgny Handreck",
                mail: "ekonomi@swag.mensa.se",
            },
            {
                title: "Fadderverksamhet",
                body: "Anders Nylund",
                mail: "fadder@swag.mensa.se",
            },
            {
                title: "Hemsida/IT",
                body: "Anders Olsson",
                mail: "web@swag.mensa.se",
            },
        ];
        return (
            <div className="container">
                <div className="row justify-content-center">
                    {personInfos.map((personInfo, index) => (
                        <div key={index} className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 mt-2 mb-2">
                            {Contact.renderPerson(personInfo)}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private static renderPerson(personInfo: PersonInfo): ReactNode {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className={`card-title ${personInfo.body ? "" : "mb-0"}`}>{personInfo.title}</h5>
                    {personInfo.body && <p className="card-text">{personInfo.body}</p>}
                </div>
                <ul className="list-group list-group-flush">
                    {personInfo.mail &&
                        <li className="list-group-item">
                            <a href={"mailto:" + personInfo.mail}>{personInfo.mail}</a>
                        </li>}
                    {personInfo.phone &&
                        <li className="list-group-item">
                            <a href={"tel:" + NumberUtil.removeNonDigits(personInfo.phone)}>{NumberUtil.formatPhoneNumber(personInfo.phone)}</a>
                        </li>}
                </ul>
            </div>
        );
    }

}

export default Contact;
