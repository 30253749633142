import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class DialogUtil {

    public async confirm(message: string): Promise<boolean> {
        return new Promise(resolve => confirmAlert({
            message,
            buttons: [
                {
                    label: "OK",
                    onClick: () => resolve(true),
                },
                {
                    label: "Avbryt",
                    onClick: () => resolve(false),
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => resolve(false),
            onClickOutside: () => resolve(false),
            onKeypressEscape: () => resolve(false),
        }));
    }

}

export default new DialogUtil();
