import * as React from "react";
import {Component, ReactNode} from "react";
import {GlobalContextConsumer} from "../context";
import {Images} from "../media";
import {AccessLevel, Notice, ScheduleItem} from "../model";
import {BackendService, NavigationService, UserService} from "../services";
import {DialogUtil, MetaData} from "../util";
import AddNotice from "./AddNotice";
import AddScheduleItem from "./AddScheduleItem";
import "./App.css";
import {Contact} from "./index";
import NoticeView from "./NoticeView";
import Rooms from "./Rooms";
import Schedule from "./Schedule";
import SubPage from "./SubPage";
import UserArea from "./UserArea";

type StateType = {
    subPage: SubPage | null;
    subPageData: any;
    notices: Notice[];
}

class App extends Component<{}, StateType> {

    state = {
        subPage: null,
        subPageData: null as any,
        notices: new Array<Notice>(),
    };

    menuItems = [
        {
            title: "Program",
            image: Images.schedule,
            action: () => NavigationService.goTo(SubPage.SCHEDULE),
            isCurrent: () => this.state.subPage === SubPage.SCHEDULE,
        },
        {
            title: "Rumskarta",
            image: Images.rooms,
            action: () => NavigationService.goTo(SubPage.ROOMS),
            isCurrent: () => this.state.subPage === SubPage.ROOMS,
        },
        {
            title: "visitsundsvall.se",
            image: Images.sundsvall,
            action: () => window.location.assign("https://visitsundsvall.se"),
            isCurrent: () => false,
        },
        {
            title: "Kontakt",
            image: Images.contact,
            action: () => NavigationService.goTo(SubPage.CONTACT),
            isCurrent: () => this.state.subPage === SubPage.CONTACT,
        },
    ];

    async componentDidMount(): Promise<void> {
        NavigationService.registerLocationChangeListener("App", this.onLocationChanged.bind(this), true);
        await this.reloadNotices();
    }

    async reloadNotices(): Promise<void> {
        const notices = await BackendService.getNotices();
        this.setState({notices});
    }

    componentWillUnmount(): void {
        NavigationService.unregisterLocationChangeListener("App");
    }

    onLocationChanged(newLocation: string, oldLocation: string, extra?: any): void {
        for (const subPage of Object.values(SubPage)) {
            if (subPage === newLocation) {
                NavigationService.setPreviousPage("");
                if (extra) {
                    this.setState({subPage, subPageData: extra});
                } else {
                    this.setState({subPage});
                }
                return;
            }
        }
        this.setState({subPage: null});
        NavigationService.setPreviousPage(null);
        NavigationService.goTo("");
    }

    render(): ReactNode {
        const {subPage} = this.state;
        return (
            <div className="App">
                <nav className={"navbar navbar-dark" + (subPage ? "" : " flex-column")}>
                    {subPage &&
                        <button className="navbar-toggler" type="button" aria-label="Tillbaka"
                                onClick={() => NavigationService.goToPreviousPage()}>
                            <div className="navbar-toggler-icon"
                                 style={{backgroundImage: "none", position: "relative"}}>
                                <span style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                                >&#x276E;</span>
                            </div>
                        </button>}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="navbar-brand ml-1 ml-sm-2 mr-1 mr-sm-2 p-0" href="#">
                        <img src={Images.logo} alt="Mensa SWAG 2022 i Sundsvall" height="70px"/>
                    </a>
                    {subPage &&
                        <>
                            <button className="navbar-toggler" type="button"
                                    data-toggle="collapse" data-target="#navbarItems"
                                    aria-controls="navbarItems" aria-expanded="false" aria-label="Meny">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarItems">
                                <div className="navbar-nav">
                                    {this.menuItems.map(item => (
                                        <button key={item.title}
                                                className={"btn nav-link" + (item.isCurrent() ? " active" : "")}
                                                data-toggle="collapse" data-target="#navbarItems"
                                                onClick={item.action}
                                        >{item.title} {item.isCurrent() &&
                                            <span className="sr-only">(aktuell)</span>}</button>
                                    ))}
                                    <GlobalContextConsumer>
                                        {({userData: {loggedIn}}) => loggedIn &&
                                            <button className="btn nav-link"
                                                    data-toggle="collapse" data-target="#navbarItems"
                                                    onClick={() => UserService.logOut()}
                                            >Logga ut</button>}
                                    </GlobalContextConsumer>
                                </div>
                            </div>
                        </>}
                </nav>
                <div className="App-content mt-4 mb-4">
                    {this.renderContent()}
                </div>
                <footer className="col-12 small text-center text-black-20 pt-4 pb-2">
                    Version {MetaData.getVersion()} &copy; {MetaData.getBuildYear()}
                    <span className="d-none"><br/>Commit {MetaData.getCommitHash()}</span>
                    <br/>
                    Powered by LoPoBo
                </footer>
            </div>
        );
    }

    private renderContent(): ReactNode {
        const {subPage, subPageData} = this.state;
        if (subPage == null) {
            return this.renderDefaultContent();
        }
        switch (subPage) {
            case SubPage.SCHEDULE:
                return <Schedule/>;
            case SubPage.ROOMS:
                return <Rooms/>;
            case SubPage.CONTACT:
                return <Contact/>;
            case SubPage.ADD_NOTICE:
                return (
                    <GlobalContextConsumer>
                        {({userData: {user, loggedIn}}) =>
                            loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR ?
                                <AddNotice onChange={() => this.reloadNotices()}/> :
                                this.renderDefaultContent()
                        }
                    </GlobalContextConsumer>
                );
            case SubPage.EDIT_NOTICE:
                return (
                    <GlobalContextConsumer>
                        {({userData: {user, loggedIn}}) =>
                            loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR && subPageData instanceof Notice ?
                                <AddNotice original={subPageData} onChange={() => this.reloadNotices()}/> :
                                this.renderDefaultContent()
                        }
                    </GlobalContextConsumer>
                );
            case SubPage.ADD_SCHEDULE_ITEM:
                NavigationService.setPreviousPage(SubPage.SCHEDULE);
                return (
                    <GlobalContextConsumer>
                        {({userData: {user, loggedIn}}) =>
                            loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR ?
                                <AddScheduleItem onChange={() => undefined}/> :
                                this.renderDefaultContent()
                        }
                    </GlobalContextConsumer>
                );
            case SubPage.EDIT_SCHEDULE_ITEM:
                NavigationService.setPreviousPage(SubPage.SCHEDULE);
                return (
                    <GlobalContextConsumer>
                        {({userData: {user, loggedIn}}) =>
                            loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR && subPageData instanceof ScheduleItem ?
                                <AddScheduleItem original={subPageData} onChange={() => undefined}/> :
                                this.renderDefaultContent()
                        }
                    </GlobalContextConsumer>
                );
        }
    }

    private renderDefaultContent(): ReactNode {
        const {notices} = this.state;
        return (
            <div className="container col-sm-10 col-lg-8">
                <GlobalContextConsumer>
                    {({userData: {user}}) => user && (
                        <div className="row">
                            <div className="col-12 mb-2">
                                <UserArea/>
                            </div>
                        </div>
                    )}
                </GlobalContextConsumer>
                <div className="row justify-content-center">
                    {notices.map(notice => (
                        <div key={notice.id} className="col-12 mt-2 mb-2">
                            <NoticeView key={notice.id}
                                        notice={notice}
                                        onEdit={() => this.editNotice(notice)}
                                        onDelete={() => this.deleteNotice(notice)}
                            />
                        </div>
                    ))}
                    <GlobalContextConsumer>
                        {({
                              userData: {
                                  user,
                                  loggedIn,
                              },
                          }) => loggedIn && user && user.accessLevel >= AccessLevel.MODERATOR &&
                            <button className="btn btn-link" onClick={() => NavigationService.goTo(SubPage.ADD_NOTICE)}>
                                Lägg till notis
                            </button>
                        }
                    </GlobalContextConsumer>
                </div>
                <div className="row pl-1 pr-1">
                    {this.menuItems.map(item => (
                        <div key={item.title}
                             className="col-6 mt-2 mb-2 pl-1 pl-md-2 pl-xl-3 pr-1 pr-md-2 pr-xl-3">
                            {App.renderCard(item.title, item.image, item.action)}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private static renderCard(caption: string, image: string, action: () => void): ReactNode {
        return (
            <div className="card" role="button" onClick={action}>
                <img src={image} className="card-img-top" alt={caption}/>
                <div className="card-body pl-2 pr-2">
                    <h5 className="card-title">{caption}</h5>
                </div>
            </div>
        );
    }

    // noinspection JSMethodCanBeStatic
    private async editNotice(notice: Notice): Promise<void> {
        NavigationService.goTo(SubPage.EDIT_NOTICE, notice);
    }

    private async deleteNotice(notice: Notice): Promise<void> {
        if (await DialogUtil.confirm("Är du säker på att du vill ta bort notisen?")) {
            try {
                await BackendService.deleteNotice(notice.id);
            } catch (ignored) {
                return await this.reloadNotices();
            }
            const {notices} = this.state;
            this.setState({
                notices: notices.filter(n => n.id !== notice.id),
            });
        }
    }

}

export default App;
