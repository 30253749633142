import * as React from "react";
import {Component, ReactNode} from "react";
// @ts-ignore
import {MapInteractionCSS} from "react-map-interaction";
import {Images} from "../media";

type ValueType = {
    scale: number;
    translation: { x: number, y: number };
};

type StateType = {
    value: ValueType;
};

class Rooms extends Component<{}, StateType> {

    state = {
        value: {
            scale: 1,
            translation: {x: 0, y: 0},
        },
    };

    render(): ReactNode {
        const {value} = this.state;
        return (
            <MapInteractionCSS
                value={value}
                onChange={(value: ValueType) => this.setState({value})}
            >
                <img
                    src={Images.map}
                    onLoad={event => this.onload(event.currentTarget)}
                    alt="Rumskarta"
                />
            </MapInteractionCSS>
        );
    }

    onload(element: HTMLImageElement): void {
        const {value} = this.state;
        const {offsetWidth} = element;
        value.scale = window.innerWidth / offsetWidth;
        this.setState({value});
    }

}

export default Rooms;
