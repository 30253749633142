type Constructor<T> = new (...args: any[]) => T;

class ArrayUtil {

    public isArrayOfType<T>(array: any, type: Constructor<T>): array is T[] {
        return Array.isArray(array) && array.every(it => it instanceof type);
    }

    public find<T>(array: T[], predicate: (element: T) => boolean): T | null {
        for (const element of array) {
            if (predicate(element)) {
                return element;
            }
        }
        return null;
    }

    public contains<T>(array: T[], predicate: (element: T) => boolean): boolean {
        for (const element of array) {
            if (predicate(element)) {
                return true;
            }
        }
        return false;
    }

}

export default new ArrayUtil();
