const {version: rawVersion} = require("../../package.json");
const {buildYear, commitHash} = require("../build_data.json");

class MetaData {

    private version;

    constructor() {
        if (this.isRelease()) {
            this.version = rawVersion;
        } else if (this.isBeta()) {
            this.version = rawVersion + "-beta";
        } else {
            this.version = rawVersion + "-alpha";
        }
    }

    getRawVersion(): string {
        return rawVersion;
    }

    getVersion(): string {
        return this.version;
    }

    isRelease(): boolean {
        return (
            process.env.NODE_ENV === "production" &&
            process.env.REACT_APP_BETA !== "true"
        );
    }

    isBeta(): boolean {
        return (
            process.env.NODE_ENV === "production" &&
            process.env.REACT_APP_BETA === "true"
        );
    }

    getBuildYear(): string {
        return buildYear;
    }

    getCommitHash(): string {
        return commitHash;
    }

}

export default new MetaData();
