import * as React from "react";
import {Component, ReactNode, SyntheticEvent} from "react";
import {ScheduleItem} from "../model";
import {BackendService, NavigationService} from "../services";
import {StringUtil} from "../util";
import SubPage from "./SubPage";

const preventDefault = function <T extends SyntheticEvent>(action: (event: T) => void): (event: T) => void {
    return event => {
        event.preventDefault();
        action(event);
    };
};

type PropsType = {
    original?: ScheduleItem;
    onChange: () => void;
}

type StateType = {
    date: string;
    startTime: string;
    endTime: string;
    title: string;
    preamble: string;
    room: string;
    swagLink: string;
    bgcolor: string;
    errorMessage: string | null;
}

class AddScheduleItem extends Component<PropsType, StateType> {

    state = {
        date: this.props.original ? this.props.original!.date : "2022-05-",
        startTime: this.props.original ? this.props.original!.startTime : "",
        endTime: this.props.original ? this.props.original!.endTime : "",
        title: this.props.original ? this.props.original!.title : "",
        preamble: this.props.original ? this.props.original!.preamble : "",
        room: this.props.original ? this.props.original!.room : "",
        swagLink: this.props.original ? this.props.original!.swagLink : "",
        bgcolor: this.props.original ? this.props.original!.bgcolor : "",
        errorMessage: null,
    };

    render(): ReactNode {
        const {original} = this.props;
        const {date, startTime, endTime, title, preamble, room, swagLink, bgcolor, errorMessage} = this.state;
        return (
            <div className="text-left ml-3 ml-md-5 mr-3 mr-md-5 pl-lg-5 pr-lg-5">
                <h3 className="pb-2">{original ? "Ändra händelse" : "Lägg till händelse"}</h3>
                {errorMessage &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                <form onSubmit={preventDefault(() => original ? this.editScheduleItem() : this.addScheduleItem())}>
                    <div className="form-group row">
                        <label htmlFor="date" className="col-sm-2 col-form-label">Datum</label>
                        <div className="col-sm-10">
                            <input id="date"
                                   className="form-control"
                                   type="text"
                                   name="date"
                                   placeholder="ÅÅÅÅ-MM-DD"
                                   minLength={10}
                                   maxLength={10}
                                   value={date}
                                   onChange={event => this.setState({date: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="startTime" className="col-sm-2 col-form-label">Starttid</label>
                        <div className="col-sm-10">
                            <input id="startTime"
                                   className="form-control"
                                   type="text"
                                   name="startTime"
                                   placeholder="HH:mm:ss"
                                   minLength={1}
                                   maxLength={8}
                                   value={startTime}
                                   onChange={event => this.setState({startTime: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="endTime" className="col-sm-2 col-form-label">Sluttid</label>
                        <div className="col-sm-10">
                            <input id="endTime"
                                   className="form-control"
                                   type="text"
                                   name="endTime"
                                   placeholder="HH:mm:ss"
                                   minLength={1}
                                   maxLength={8}
                                   value={endTime}
                                   onChange={event => this.setState({endTime: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="title" className="col-sm-2 col-form-label">Titel</label>
                        <div className="col-sm-10">
                            <input id="title"
                                   className="form-control"
                                   type="text"
                                   name="title"
                                   minLength={1}
                                   maxLength={100}
                                   value={title}
                                   onChange={event => this.setState({title: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="preamble" className="col-sm-2 col-form-label">Ingress</label>
                        <div className="col-sm-10">
                            <input id="preamble"
                                   className="form-control"
                                   type="text"
                                   name="preamble"
                                   minLength={0}
                                   maxLength={100}
                                   value={preamble}
                                   onChange={event => this.setState({preamble: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="room" className="col-sm-2 col-form-label">Rum/plats</label>
                        <div className="col-sm-10">
                            <input id="room"
                                   className="form-control"
                                   type="text"
                                   name="room"
                                   minLength={0}
                                   maxLength={100}
                                   value={room}
                                   onChange={event => this.setState({room: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="swagLink" className="col-sm-2 col-form-label">Länk till mer info</label>
                        <div className="col-sm-10">
                            <input id="swagLink"
                                   className="form-control"
                                   type="text"
                                   name="swagLink"
                                   minLength={0}
                                   maxLength={250}
                                   value={swagLink}
                                   onChange={event => this.setState({swagLink: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="bgcolor" className="col-sm-2 col-form-label">Bakgrundsfärg</label>
                        <div className="col-sm-10">
                            <div className="input-group">
                                <input id="bgcolor"
                                       className="form-control"
                                       type="text"
                                       name="bgcolor"
                                       placeholder="#FFFFFF"
                                       minLength={0}
                                       maxLength={250}
                                       value={bgcolor}
                                       onChange={event => this.setState({bgcolor: event.target.value})}
                                />
                                {["#F7E6CB", "#B3D8C6", "#D6C9B2"].map(color => {
                                    const current = StringUtil.caseInsensitiveEquals(bgcolor, color);
                                    return (
                                        <div key={color} className="input-group-append">
                                            <button
                                                className={`btn ${current ? "btn-outline-dark" : "btn-outline-secondary"} pl-4 pr-4`}
                                                style={{
                                                    backgroundColor: color,
                                                    ...(current ? {
                                                        borderWidth: "2px",
                                                    } : {}),
                                                }}
                                                aria-label={color}
                                                onClick={preventDefault(() => this.setState({bgcolor: color}))}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <button className="btn btn-primary" type="submit">
                                {original ? "Spara" : "Lägg till"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async addScheduleItem(): Promise<void> {
        const {date, startTime, endTime, title, preamble, room, swagLink, bgcolor} = this.state;
        const newScheduleItem = new ScheduleItem(-1, date.trim(), AddScheduleItem.sanitizeTime(startTime),
            AddScheduleItem.sanitizeTime(endTime), title.trim(), preamble.trim(), room.trim(), swagLink.trim(), bgcolor.trim());
        try {
            await BackendService.addScheduleItem(newScheduleItem);
        } catch (ignored) {
            this.setState({errorMessage: "Kunde inte lägga till händelse. Vänligen pröva igen."});
            return;
        }
        this.props.onChange();
        NavigationService.goTo(SubPage.SCHEDULE);
    }

    async editScheduleItem(): Promise<void> {
        const {original} = this.props;
        const {date, startTime, endTime, title, preamble, room, swagLink, bgcolor} = this.state;
        const editedScheduleItem = new ScheduleItem(original!.id, date.trim(), AddScheduleItem.sanitizeTime(startTime),
            AddScheduleItem.sanitizeTime(endTime), title.trim(), preamble.trim(), room.trim(), swagLink.trim(), bgcolor.trim());
        if (!original!.equals(editedScheduleItem)) {
            try {
                await BackendService.editScheduleItem(editedScheduleItem);
            } catch (ignored) {
                this.setState({errorMessage: "Kunde inte ändra händelse. Vänligen pröva igen."});
                return;
            }
            this.props.onChange();
        }
        NavigationService.goTo(SubPage.SCHEDULE);
    }

    static sanitizeTime(time: string): string {
        const pattern = /^\s*(\d\d?)(?:[:,.](\d\d)(?:[:,.](\d\d))?)?\s*$/;
        const match = time.match(pattern);
        if (match) {
            const hour = match[1].length === 1 ? `0${match[1]}` : match[1];
            const minute = match[2] || "00";
            const second = match[3] || "00";
            return `${hour}:${minute}:${second}`;
        }
        return "";
    }

}

export default AddScheduleItem;
