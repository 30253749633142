class StringUtil {

    public caseInsensitiveCompare(lhs: string, rhs: string): number {
        return lhs.localeCompare(rhs, undefined, {sensitivity: "accent"});
    }

    public caseInsensitiveEquals(lhs: string | null | undefined, rhs: string | null | undefined): boolean {
        return typeof lhs === "string" && typeof rhs === "string"
            ? this.caseInsensitiveCompare(lhs, rhs) === 0
            : lhs === rhs;
    }

}

export default new StringUtil();
