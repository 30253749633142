import {User} from "../model";
import BackendService from "./BackendService";

type UserData = {
    user: User | null;
    loggedIn: boolean;
}

type UserDataChangeListener = (userData: UserData) => void;

class UserService {

    private cachedUserData: Promise<UserData> | null = null;
    private userDataChangeListeners = new Map<string, UserDataChangeListener>();

    public registerUserDataChangeListener(id: string, listener: UserDataChangeListener, triggerInitial?: boolean): void {
        this.userDataChangeListeners.set(id, listener);
        if (triggerInitial) {
            this.getUserData().then(listener);
        }
    }

    public unregisterUserDataChangeListener(id: string): void {
        this.userDataChangeListeners.delete(id);
    }

    private notifyListeners(userData: UserData) {
        this.userDataChangeListeners.forEach(listener => listener(userData));
    }

    public getUserData(): Promise<UserData> {
        if (this.cachedUserData === null) {
            this.cachedUserData = this.getLiveUserData();
        }
        return this.cachedUserData;
    }

    public async logIn(memberId: string, password: string): Promise<User> {
        const user = await BackendService.logIn(memberId, password);
        const userData = {user, loggedIn: true};
        this.cachedUserData = Promise.resolve(userData);
        this.notifyListeners(userData);
        return user;
    }

    public async logOut(): Promise<void> {
        await BackendService.logOut();
        this.cachedUserData = null;
        await this.getUserData();
    }

    private async getLiveUserData(): Promise<UserData> {
        const id = UserService.getQueryParameter("i");
        const hash = UserService.getQueryParameter("h");
        const userData = await BackendService.checkId(id, hash);
        this.notifyListeners(userData);
        return userData;
    }

    private static getQueryParameter(name: string): string | null {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        return params.get(name);
    }

}

export type {UserData, UserDataChangeListener};
export default new UserService();
